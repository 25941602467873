export default [
  {
    title: "Users",
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: "Create QR",
    route: "createqr",
    icon: "HomeIcon",
  },
];
